<template>
  <div class="orderGoods">
    <div class="total">共{{ cartInfo.length }}件商品</div>
    <div class="goodWrapper">
      <div
        class="item acea-row row-between-wrapper"
        v-for="cart in cartInfo"
        :key="cart.id"
      >
        <div class="pictrue">
          <img v-lazy="cart.productInfo.image" alt="img" class="image" />
        </div>
        <div class="text">
          <div class="acea-row row-between-wrapper">
            <div class="name line1">{{ cart.productInfo.store_name }}</div>
            <div class="num">x {{ cart.cart_num }}</div>
          </div>
          <div class="attr line1" v-if="cart.productInfo.attrInfo">
            {{ cart.productInfo.attrInfo.suk }}
          </div>
          <div class="money font-color-red">
            ￥{{
              cart.productInfo.attrInfo
                ? cart.productInfo.attrInfo.price
                : cart.productInfo.price
            }}
                <span style="font-size: 0.15rem;" v-show="exchangeDeductPrice[cart.id]"> 换购优惠:￥{{exchangeDeductPrice[cart.id]}}</span>
                <!-- <span style="font-size: 0.15rem;color:#dadada" v-show="cart.productInfo.ban_coupon">（不参与优惠）</span> -->
                <span style="font-size: 0.15rem;color:#dadada" v-show="cart.productInfo.ban_coupon">（不可使用优惠券）</span>
          </div>
          <!-- <div
            class="evaluate"
            v-if="evaluate === 3"
            @click="$router.push({ path: '/goods_evaluate/' + cart.unique })"
          >
            评价
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderGoods",
  props: {
    evaluate: Number,
    cartInfo: {
      type: Array,
      default: () => []
    },
    exchangeDeductPrice: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {};
  },
  mounted: function() {},
  methods: {}
};
</script>
